
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import ListCard from '@/components/ListCard.vue';
import { ListCardItem } from '@/models/list-card-item';
import PresentationSessionForm from '@/components/PresentationSessionForm.vue';
import { PresentationSessionModel } from '@/models/presentation-session-model';
import PresentationSessionService from '@/services/presentation-sessions-service';
import { setFocus } from '@/services/dom-tools';

export default defineComponent({
  name: 'Sessions',
  components: {
    ListCard,
    PresentationSessionForm,
  },

  setup() {
    const store = useStore();
    return { store };
  },

  mounted() {
    this.store.dispatch(ActionTypes.LOAD_ALL_DATA).then(
      () => setFocus('project'),
      () => this.$router.push({ name: "Signin" })
    );
  },

  data: () => ({
    item: PresentationSessionService.createNewSession(),
  }),

  computed: {
    loading(): boolean { return this.store.state.loadingData; },
    list(): ListCardItem[] { return this.store.getters.sessionsList; },
  },

  methods: {
    getSessionById(id: number): PresentationSessionModel {
      return this.store.getters.getSessionById(id);
    },

    onItemClicked(item: ListCardItem) {
      // TODO: Navigate to session notes instead.
      this.item = this.getSessionById(+item.id);
      setFocus('title');
    },

    onAddClicked() {
      this.item = PresentationSessionService.createNewSession();
      setFocus('project');
    },

    onEditClicked(item: ListCardItem) {
      this.item = this.getSessionById(+item.id);
    },

    onSaveDone(item: PresentationSessionModel) {
      this.item = item;
    },

    onDeleteDone() {
      this.onAddClicked();
    },
  }
});
